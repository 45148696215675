/*********************
01 – HEADER FONT SIZES
*********************/

// This calls a particular header size e.g. h2. To call in _main.scss use: font-size: header-size(medium, h2);

@function header-size($breakpoint, $size) {
  @return rem-calc(map-get(map-get(map-get($header-styles, "small"), $size), "font-size") * 1px);
}


// This calls the responsive header size. To call in _main.scss use: @include header-styles(h4);

@mixin header-styles($type){
  font-size: rem-calc(map-get(map-get(map-get($header-styles, "small"), $type), "font-size") * 1px);
  /*@include breakpoint(medium){
    font-size: rem-calc(map-get(map-get(map-get($header-styles, "medium"), $type), "font-size") * 1px);
  }*/
}




/*********************
03 – SPACING STYLES
*********************/

// Spacing

@mixin spaced {
    margin-top: 8vh !important;
    margin-bottom: 9.2vh !important;
}

@mixin spaced-top {
    margin-top: 8vh !important;
}

@mixin spaced-bottom {
    margin-bottom: 9.2vh !important;
}

.spaced {
    @include spaced;
}

.spaced-top {
    @include spaced-top;
}

.spaced-bottom {
    @include spaced-bottom;
}

// Quarter Spacing

@mixin quarter-spaced {
    margin-top: 2vh !important;
    margin-bottom: 2.3vh !important;
}

@mixin quarter-spaced-top {
    margin-top: 2vh !important;
}

@mixin quarter-spaced-bottom {
    margin-bottom: 2.3vh !important;
}

.quarter-spaced {
    @include quarter-spaced;
}

.quarter-spaced-top {
    @include quarter-spaced-top;
}

.quarter-spaced-bottom {
    @include quarter-spaced-bottom;
}

// Half Spacing

@mixin half-spaced {
    margin-top: 4vh !important;
    margin-bottom: 4.6vh !important;
}

@mixin half-spaced-top {
    margin-top: 4vh !important;
}

@mixin half-spaced-bottom {
    margin-bottom: 4.6vh !important;
}

.half-spaced {
    @include half-spaced;
}

.half-spaced-top {
    @include half-spaced-top;
}

.half-spaced-bottom {
    @include half-spaced-bottom;
}

// Inner Spacing

@mixin inner-spaced {
    padding-top: 8vh !important;
    padding-bottom: 9.2vh !important;
}

@mixin inner-spaced-top {
    padding-top: 8vh !important;
}

@mixin inner-spaced-bottom {
    padding-bottom: 9.2vh !important;
}

.inner-spaced {
    @include inner-spaced;
}

.inner-spaced-top {
    @include inner-spaced-top;
}

.inner-spaced-bottom {
    @include inner-spaced-bottom;
}


// Quarter Inner Spacing

@mixin quarter-inner-spaced {
    padding-top: 2vh !important;
    padding-bottom: 2.3vh !important;
}

@mixin quarter-inner-spaced-top {
    padding-top: 2vh !important;
}

@mixin quarter-inner-spaced-bottom {
    padding-bottom: 2.3vh !important;
}

.quarter-inner-spaced {
    @include quarter-inner-spaced;
}

.quarter-inner-spaced-top {
    @include quarter-inner-spaced-top;
}

.quarter-inner-spaced-bottom {
    @include quarter-inner-spaced-bottom;
}

// Half Inner Spacing

@mixin half-inner-spaced {
    padding-top: 4vh !important;
    padding-bottom: 4.2vh !important;
}

@mixin half-inner-spaced-top {
    padding-top: 4vh !important;
}

@mixin half-inner-spaced-bottom {
    padding-bottom: 4.2vh !important;
}

.half-inner-spaced {
    @include half-inner-spaced;
}

.half-inner-spaced-top {
    @include half-inner-spaced-top;
}

.half-inner-spaced-bottom {
    @include half-inner-spaced-bottom;
}




/*********************
04 – TRANSITIONS
*********************/

@mixin transition-1 {
    -webkit-transition: all 250ms ease;
    -moz-transition: all 250ms ease;
    -o-transition: all 250ms ease;
    transition: all 250ms ease;
}

.transition-1 {
    @include transition-1;
}

@mixin transition-2 {
    -webkit-transition: all 250ms ease 300ms;
    -moz-transition: all 250ms ease 300ms;
    -o-transition: all 250ms ease 300ms;
    transition: all 250ms ease 300ms;
}

.transition-2 {
    @include transition-2;
}

@mixin transition-3 {
    -webkit-transition: all 250ms ease 600ms;
    -moz-transition: all 250ms ease 600ms;
    -o-transition: all 250ms ease 600ms;
    transition: all 250ms ease 600ms;
}

.transition-3 {
    @include transition-3;
}

@mixin transition-4 {
    -webkit-transition: all 250ms ease 900ms;
    -moz-transition: all 250ms ease 900ms;
    -o-transition: all 250ms ease 900ms;
    transition: all 250ms ease 900ms;
}

.transition-4 {
    @include transition-4;
}

@mixin transition-5 {
    -webkit-transition: all 250ms ease 1200ms;
    -moz-transition: all 250ms ease 1200ms;
    -o-transition: all 250ms ease 1200ms;
    transition: all 250ms ease 1200ms;
}

.transition-5 {
    @include transition-5;
}

@mixin transition-6 {
    -webkit-transition: all 250ms ease 1500ms;
    -moz-transition: all 250ms ease 1500ms;
    -o-transition: all 250ms ease 1500ms;
    transition: all 250ms ease 1500ms;
}

.transition-6 {
    @include transition-6;
}




/*********************
05 – APPEAR ON LOAD EFFECT STYLES
*********************/

@mixin appear-load-1 {
    opacity: 0;
    @include transition-2;

    &.visible {
        opacity: 1;
        @include transition-3;
    }
}

.appear-load-1 {
    @include appear-load-1;
}


@mixin appear-load-2 {
    opacity: 0;
    @include transition-3;

    &.visible {
        opacity: 1;
        @include transition-4;
    }
}

.appear-load-2 {
    @include appear-load-2;
}

@mixin appear-load-3 {
    opacity: 0;
    @include transition-4;

    &.visible {
        opacity: 1;
        @include transition-5;
    }
}

.appear-load-3 {
    @include appear-load-3;
}

@mixin appear-load-4 {
    opacity: 0;
    @include transition-5;

    &.visible {
        opacity: 1;
        @include transition-6;
    }
}

.appear-load-4 {
    @include appear-load-4;
}




/*********************
VIEWPORT HEIGHT STYLES
*********************/

.full-viewport {
    //height: 800px; /* Some tweener fallback that doesn't look awful */
    min-height: 100vh !important;
}

.ninety-viewport {
    //height: 800px; /* Some tweener fallback that doesn't look awful */
    min-height: 90vh !important;
}

.ten-viewport {
    //height: 800px; /* Some tweener fallback that doesn't look awful */
    min-height: 10vh !important;
}




/*********************
CUSTOM STYLES
*********************/

#banner {
    //border: 1vh solid $white;
    background-image: url("../img/horizon-projects-banner.jpg");
    background-position: center;
    background-size: cover;
    background-color: $primary-color;

    #banner-overlay, footer {
        background-color: rgba( $primary-color, 0.95 );
    }

    #banner-overlay {

        .logo {
            max-width: 12rem;

            @include breakpoint(medium) {
                max-width: 15rem;
            }
        }

        /* h1 {
            color: $white;
            text-transform: uppercase;
            @include header-styles(h3);

            @include breakpoint(medium) {
                @include header-styles(h4);
            }
        } */

        .lead {
            color: $white;
        }

        p {
            color: $white;

            span {
                text-transform: uppercase;
                @include header-styles(h6);
            }

            a {
                color: $white;
                text-decoration: underline;
                @include transition-2;

                &:hover {
                    color: $secondary-color;
                    text-decoration: none;
                    @include transition-1;
                }
            }
        }

        .social-icon {
          width: 24px;
        }

        #mobile-buttons {
            //border-bottom: thin solid $white;
            @include half-inner-spaced-bottom;

            .button {
                font-weight: $global-weight-bold;
                text-transform: uppercase;
            }
        }
    }
}

footer {
    color: $white;
    font-weight: 400;

    .moose-mark {
        max-width: 1.5rem;
        opacity: 1;
        @include transition-2;

        &:hover {
            opacity: 0.75;
            @include transition-1;
        }
    }
}
